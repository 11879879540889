<template>
  <div v-if="!overlay">
    <div v-show="okRules">
    <div class="maxlayout container container__margin">
      <!-- SEARCH sezione per contenere i campi di ricerca -->
      <div class="search bg-grey border-red ico-doc">
        <div class="search__caption">
          <div class="search__caption__copy">
            <div class="search__caption__copy__searchbox">
              <div class="azioni-top-right-azp">
                <div class="azioni-title-main-azp">
                  <div class="azioni-title-azp-ricerca">
                    <h1>Cerca in Le mie Riviste</h1>
                  </div>
                  <div
                    class="search-box-in-rip clearfix hole"
                    v-show="!showResAdv"
                  >
                    <div class="search-small-icon-hp">
                      <img src="images/ico_search.svg" alt="" />
                    </div>
                    <div class="search-small-right-hp">
                      <div class="search-small-input-hp">
                        <v-text-field
                          v-on:keyup="keyPress"
                          dense
                          class="ctlg"
                          v-model="searchText"
                          placeholder="Cerca per parole"
                          @click:append="
                            page = 1;
                            research(0, 8);
                          "
                          append-icon="search"
                        ></v-text-field>
                        <div class="search-small-main-btn-hp">
                          <input type="submit" value="cerca" class="cta red" @click="research()" />
                          <div class="advance-hp">
                            <a
                              @click="showResAdv = !showResAdv"
                              v-if="!showResAdv"
                              class="lnk blue"
                              >Ricerca avanzata</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="search-box-in-rip clearfix hole-advanced"
                    v-show="showResAdv"
                  >
                    <div class="search-small-icon-hp">
                      <img src="images/ico_search.svg" alt="" />
                      <h4>Ricerca Avanzata</h4>
                    </div>
                    <div class="search-small-right-hp">
                      <div class="search-small-input-hp">
                        <v-text-field
                          v-on:keyup="keyPress"
                          dense
                          class="ctlg"
                          v-model="searchText"
                          placeholder="Cerca per parole"
                          @click:append="
                            page = 1;
                            research(0, 8);
                          "
                          append-icon="search"
                        ></v-text-field>
                        <v-select
                          class="filter__search__select"
                          name
                          v-model="filter_onwords"
                          :value="filter_onwords"
                          :items="allWordsSelect"
                        >
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- pannello ricerca avanzata - va mostrato al click della CTA Ricerca Avanzata -->
      <div class="search__advanced" v-show="showResAdv">
        <div class="search__advanced__copy">
          <div class="search__advanced__copy__item">
            <div class="advanced__row no-gutters">
              <div class="flex advanced__row no-gutters col-sm-4 col-12">
                <div class="flex advanced__row col-sm-4 col-12">
                  <label class="advanced__row__title">Data</label>
                </div>
                <div class="flex advanced__row col-sm-8 col-12">
                  <label>Dal</label>
                  <v-text-field
                    label="Anno"
                    type="number"
                    v-model="dateFrom"
                    :rules="[rules.min]"
                    class="inp"
                  ></v-text-field>
                  <label>Al</label>
                  <v-text-field
                    label="Anno"
                    type="number"
                    v-model="dateTo"
                    :rules="[rules.max]"
                    class="inp"
                  ></v-text-field>
                </div>
              </div>
              <div class="flex advanced__row col-sm-4 col-12"></div>
              <div class="flex advanced__row col-sm-4 col-12"></div>
            </div>
          </div>
          <div class="search__advanced__copy__item">
            <div class="advanced__row no-gutters">
              <div class="flex advanced__row no-gutters col-sm-4 col-12">
                <div class="flex advanced__row col-sm-4 col-12">
                  <label class="advanced__row__title">Rivista</label>
                </div>
                <div class="flex advanced__row col-sm-8 col-12">
                  <v-select
                    class="filter__search__select"
                    dense
                    name
                    v-model="filter_onriviste"
                    :value="filter_onriviste"
                    :items="rivisteSelect"
                    item-text="descrizione"
                    clearable
                  >
                  </v-select>
                </div>
              </div>
              <div class="flex advanced__row col-sm-4 col-12">
                <div class="flex advanced__row col-sm-4 col-12">
                  <label :class="[{'advanced__row__title': true, 'disabled': (checkOrDisable('year') || yearSelect.length == 0)}]">Anno</label>
                </div>
                <div class="flex advanced__row col-sm-8 col-12">
                  <v-select
                    class="filter__search__select"
                    dense
                    name
                    :disabled="!filter_onriviste || yearSelect.length == 0"
                    v-model="filter_onyear"
                    :value="filter_onyear"
                    :items="yearSelect"
                    item-text="anno_riv"
                    clearable
                  >
                  </v-select>
                </div>
              </div>
              <div class="flex advanced__row col-sm-4 col-12">
                <div class="flex advanced__row col-sm-4 col-12">
                  <label :class="[{'advanced__row__title': true, 'disabled': (checkOrDisable('fascicolo') || fascicoloSelect.length == 0)}]">Fascicolo</label>
                </div>
                <div class="flex advanced__row col-sm-8 col-12">
                  <v-select
                    class="filter__search__select"
                    dense
                    name
                    :disabled="!filter_onyear || fascicoloSelect.length == 0"
                    v-model="filter_onfascicolo"
                    :value="filter_onfascicolo"
                    :items="fascicoloSelect"
                    item-text="fascicolo1"
                    clearable
                  >
                  </v-select>
                </div>
              </div>
            </div>
            <div class="advanced__row no-gutters">
              <div class="flex advanced__row col-sm-4 col-12"></div>
              <div class="flex advanced__row col-sm-4 col-12">
                <div class="flex advanced__row col-sm-4 col-12">
                  <label :class="[{'advanced__row__title': true, 'disabled': (checkOrDisable('part') || partSelect.length == 0)}]">Parte</label>
                </div>
                <div class="flex advanced__row col-sm-8 col-12">
                  <v-select
                    class="filter__search__select"
                    dense
                    name
                    :disabled="!filter_onfascicolo || partSelect.length == 0"
                    v-model="filter_onpart"
                    :value="filter_onpart"
                    :items="partSelect"
                    item-text="seq_elemento"
                    clearable
                  >
                  </v-select>
                </div>
              </div>
              <div class="flex advanced__row col-sm-4 col-12">
                <div class="flex advanced__row col-sm-4 col-12">
                  <label :class="[{'advanced__row__title': true, 'disabled': checkOrDisable('page')}]">Pagina</label>
                </div>
                <div class="flex advanced__row col-sm-8 col-12">
                  <v-text-field
                    dense
                    class="ctlg"
                    :disabled="!filter_onfascicolo"
                    v-model="filter_onpages"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </div>
          <div class="search__advanced__copy__item">
            <div class="advanced__row no-gutters">
              <div class="flex advanced__row no-gutters col-sm-4 col-12">
                <div class="flex advanced__row col-sm-4 col-12">
                  <label :class="[{'advanced__row__title': true, 'disabled': !enableAuthor }]">Autore</label>
                </div>
                <div class="flex advanced__row col-sm-8 col-12">
                  <v-text-field
                    dense
                    class="ctlg"
                    v-model="author"
                    placeholder="Inserisci un autore"
                    :disabled="!enableAuthor"
                  ></v-text-field>
                </div>
              </div>
              <div class="flex advanced__row col-sm-4 col-12">
                <div class="flex advanced__row col-sm-4 col-12">
                  <label class="advanced__row__title">Materia</label>
                </div>
                <div class="flex advanced__row col-sm-8 col-12">
                  <v-combobox
                    class="comboMateriaHome"
                    v-model="materiaSelected"
                    :items="materie"
                    light
                    item-text="descrizione"
                    dense
                    placeholder="Materia"
                    clearable
                    offset-y
                    :menu-props="{ auto: true }"
                  ></v-combobox>
                </div>
              </div>
              <div class="flex advanced__row col-sm-4 col-12"></div>
            </div>
          </div>
          <div class="search__advanced__copy__actions">
            <a
              title="Annulla"
              class="cta red outline"
              @click="showResAdv = false; resetFields()"
            >
              <span>Annulla</span>
            </a>
            <a title="Cerca" class="cta red" @click="research()">
              <span>Cerca</span>
            </a>
          </div>
        </div>
      </div>

      <!-- SECTION  -->
      <div class="section border-red" id="grid__catalogo">
        <div class="intro">
          <h2>Le mie riviste</h2>
          <h4>Di ultima pubblicazione</h4>
        <div class="filter">
          <v-switch
          style="margin-top: -70px;"
          class="prefSwitch"
          dense
          v-model="pref"
          color="green"
          :label="`PREFERITI`"
          inset
          @change="toggleFav(item)"
        ></v-switch>
        </div>
        </div>

        <!-- GRID box per gestire la visualizzazione a griglia dei volumi -->
        <div class="grid clearfix" v-if="catalogue">
          <div class="hidden-sm-and-down">
          <div
                class="grid__item"
                v-for="item in catalogue.es.list"
                :key="item"
                @click="detail(item)"
              >
                <a class="grid__item__caption">
                  <div class="grid__item__caption__image">
                    <v-img
                      v-if="item._source.provvedimento.campiCalcolati.cover_url"
                      
                      width="217"
                      :src="fixCopertine(
                        settings.copertina +
                        '/pdf/' +
                        item._source.provvedimento.idDocMaster
                          .toString()
                          .substring(
                            item._source.provvedimento.idDocMaster.toString()
                              .length - 3,
                            item._source.provvedimento.idDocMaster.toString()
                              .length
                          ) +
                        item._source.provvedimento.campiCalcolati.cover_url +
                        '?width=217&fit=outside')
                      "
                      :aspect-ratio="0.629"
                    ></v-img>

                    <v-img
                      v-else
                      width="217"
                      
                      :src="fixCopertine(settings.configuration.placeholderIMG)"
                    ></v-img>
                  </div>
                  <div class="grid__item__caption__text">
                    <h4>{{item._source.provvedimento.eTitolo}}</h4>
                    <p>
                      <span v-if="item._source.provvedimento.collana">{{
                        item._source.provvedimento.collana.descrizione
                      }}</span>
                      <br />
                      <span
                        style="font-weight: bold; display: block"
                        v-if="false && item._source.autori"
                        >{{
                          item._source.autori[0].nome.substring(
                            0,
                            1
                          )
                        }}.
                        {{
                          item._source.autori[0].cognome
                        }}</span
                      >
                      <span
                        style="font-weight: bold"
                        v-if="false &&
                          item._source.autori &&
                          item._source.autori[1]
                        "
                        >{{
                          item._source.autori[1].nome.substring(
                            0,
                            1
                          )
                        }}.
                        {{
                          item._source.autori[1].cognome
                        }}</span
                      >
                    </p>
                  </div>
                  <div class="metadata-row" style="margin-left:15px; margin-top:-15px">
                      <span class="metadata">Anno:</span>
                      <span class="metadata__value" style="color:black">{{
                        item._source.provvedimento.annoRiv
                      }}</span>
                    </div>
                    <div class="metadata-row" style="margin-left:15px">
                      <span class="metadata">Fascicolo:</span>
                      <span class="metadata__value" style="color:black"
                        >{{ item._source.provvedimento.fascicolo1 }} 
                        {{ item._source.provvedimento.fascicolo2 ? " - " + item._source.provvedimento.fascicolo2 : "" }}</span
                      >
                    </div>
                </a>
              </div>
          </div>
          <v-card
            elevation="24"
            max-width="444"
            class="mx-auto hidden-md-and-up"
            style="width: 217px; !important"
          >
            <v-carousel
              v-model="model"
              hide-delimiters
              @change="carousel(item)"
              class="carousel"
            >
              <v-carousel-item
                v-for="item in catalogue.es.list"
                :key="item"
                @click="detail(item)"
                class="corousel__pagination-number"
              >
                <v-img
                  v-if="item._source.provvedimento.campiCalcolati.cover_url"
                  
                  width="217"
                  :src="fixCopertine(
                    settings.copertina +
                    '/pdf/' +
                    item._source.provvedimento.idDocMaster
                      .toString()
                      .substring(
                        item._source.provvedimento.idDocMaster.toString()
                          .length - 3,
                        item._source.provvedimento.idDocMaster.toString().length
                      ) +
                    item._source.provvedimento.campiCalcolati.cover_url +
                    '?width=217&fit=outside')
                  "
                  :aspect-ratio="0.629"
                ></v-img>

                <v-img
                  v-else
                  width="217"
                  
                  :src="fixCopertine(settings.configuration.placeholderIMG)"
                ></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
          <v-row
            justify="space-around"
            style="margin-top: 20px"
            class="mx-auto hidden-md-and-up"
          >
            <div class="grid__item__caption__text">
              <h4>{{ currentItem._source.provvedimento.eTitolo }}</h4>
              <p>
                <span v-if="currentItem._source.provvedimento.collana">{{
                  currentItem._source.provvedimento.collana.descrizione
                }}</span>
                <br />
                <span style="font-weight: bold" v-if="false && currentItem._source.autori"
                  >{{ currentItem._source.autori[0].nome.substring(0, 1) }}.
                  {{ currentItem._source.autori[0].cognome }} </span
                >
                <span style="font-weight: bold" v-if="false && currentItem._source.autori && currentItem._source.autori[1]"
                  >{{ currentItem._source.autori[1].nome.substring(0, 1) }}.
                  {{ currentItem._source.autori[1].cognome }}</span
                >
              </p>
            </div>
          </v-row>

          <div class="grid__actions">
            <a
              class="cta red big"
              title="Carica altri risultati"
              @click="moreResults()"
              style="margin-top:10px"
              v-if="!pref || (pref && this.catalogue.es.total > 8)"
              >Più risultati</a
            >
          </div>
        </div>

        <!-- in versione MOBILE invece del div GRID verrà mostrato il div con il carosello immagini -->
        <div class="carousel"></div>
      </div>
    </div>
    </div>
    <div v-show="!okRules" style="margin-top:-25px">
      <NoContentView @authorize="authorize" :settings="settings"></NoContentView>
    </div>
  </div>
  <h1 v-else style="text-align: center; margin: 0 auto">
    <v-progress-circular
      style="margin-top: 20px"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </h1>
</template>
<style>
.ctlg .v-input__slot {
  border: solid 1px #cdcdcd;
  height: 40px !important;
  font-size: 10pt;
}
.v-text-field__slot {
  /*margin-top: 4px;*/
}

.v-input__icon.v-input__icon--append {
  margin-top: 3px;
}
.v-card {
  transition: opacity 0.3s ease-in-out;
}
.v-menu__content {
  /*box-shadow: none !important;*/
}
.v-card:not(.on-hover) {
  opacity: 0.8;
}
.order.v-list.v-sheet.v-sheet--tile.theme--light.v-list--dense {
  margin-top: 40px !important;
  margin-left: 125px !important;
  overflow-y: hidden !important;
}
.titl {
  cursor: pointer;
}
.titl:hover {
  text-decoration: underline;
}

.disabled{
  color:#9D9F9D !important;
}

.v-input__slot input::-webkit-outer-spin-button,
.v-input__slot input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.v-input__slot input[type="number"] {
  border: none;
}

i.v-icon.notranslate.mdi.mdi-menu-down.theme--light {
  cursor: pointer !important;
}
.v-image.v-responsive.v-carousel__item.theme--dark, .v-window.carousel.v-item-group.theme--dark.v-carousel{
  height:auto !important;
}
</style>
<script>
import NoContentViewVue from "../components/volumes/NoContentView.vue";
import axios from "axios";

export default {
  props: {
    authorization: {
      type: Object,
    },
    overlay: {
      type: Boolean,
    },
    settings: {
      type: Object,
    },
  },
  components: {
    NoContentView: NoContentViewVue,
  },
  data: (vm) => ({
    page: 1,
    totalPages: 1,
    loaded: false,
    dateTo: null,
    author: "",
    user: null,
    catalogue: null,
    materie: [],
    materiaSelected: null,
    totalResults: 0,
    pref:false,
    searchText: null,
    chapterText: "",
    errorMin: false,
    okRules: false,
    errorMax: false,
    dateFrom: null,
    qsParamSearch: "",
    allWordsSelect: [
      "Tutte le parole",
      "Frase esatta"
    ],
    filter_onwords: "Tutte le parole",
    rivisteSelect: [],
    filter_onriviste: null,
    yearSelect: [],
    filter_onyear: null,
    partSelect: [],
    filter_onpart: null,
    fascicoloSelect: [],
    filter_onfascicolo: null,
    filter_onpages: null,
    rules: {
      min: (value) => vm.check(value, true),
      max: (value) => vm.check(value, false),
    },
    currentRivistaSelectedId: null,
    currentYearSelected: null,
    currentFascicoloSelected: null,
    enableAuthor: true,
    comboRiviste: [],
    list: [],
    showResAdv: false,
    nlp: null,
    currentItem: null,
    volume: null,
    isbn: null,
    currentOrder: {
      id: 1,
      name: "Più pertinente",
      sort: {
        name: "_score",
        order: "desc",
      },
    },
    orders: [
      {
        id: 1,
        name: "Più pertinente",
        sort: {
          name: "_score",
          order: "desc",
        },
      },
      {
        id: 2,
        name: "Più recente",
        sort: {
          name: "provvedimento.data",
          order: "desc",
        },
      },
    ],
  }),
  watch: {
    page(val) {
      this.research((val - 1) * 8, 8);
    },
    filter_onriviste(val) {
      var filter = this.rivisteSelect.find((x) => x.descrizione == val);

      var header = {
          Authorization: localStorage.token_biblio_riv_jwt,
      };
      if (filter) {
        this.currentRivistaSelectedId = filter.cod_riv;
        this.REQUEST(
          this.settings.configuration.apiURL +
            "/riviste/form/cb?cod_riv=" +
            filter.cod_riv,
          header,
          "GET",
          "comboYear",
          null
        );
      }
      else{
        this.filter_onyear = null;
        this.filter_onfascicolo = null;
        this.filter_onpart = null;
        this.filter_onpages = null;
      }
    },
    filter_onyear(val) {
      var filter = this.yearSelect.find((x) => x.anno_riv == val);
      this.currentYearSelected = filter ? filter.anno_riv : null;

      var header = {
          Authorization: localStorage.token_biblio_riv_jwt,
      };

      if (this.currentRivistaSelectedId && filter) {
        this.REQUEST(
          this.settings.configuration.apiURL +
            "/riviste/form/cb?cod_riv=" +
            this.currentRivistaSelectedId +
            "&anno_riv=" +
            filter.anno_riv,
          header,
          "GET",
          "comboFascicolo",
          null
        );
      }
      else{
        this.filter_onfascicolo = null;
        this.filter_onpart = null;
        this.filter_onpages = null;
      }
    },
    filter_onfascicolo(val) {
      var filter = this.fascicoloSelect.find((x) => x.fascicolo1 == val);
      this.currentFascicoloSelected = filter ? filter.fascicolo1 : null;
      var header = {
          Authorization: localStorage.token_biblio_riv_jwt,
      };

      //PBR-220
      if(filter.tipologia_doc == 12){
        this.enableAuthor = true;
      }
      else{
        this.enableAuthor = false;
        this.author = null;
      }

      if (this.currentRivistaSelectedId && this.currentYearSelected && filter) {
        var curr_fascicolo = filter.fascicolo1.replace("Fascicolo", "").trim();
        curr_fascicolo = curr_fascicolo.split("-")[0].trim();
        this.REQUEST(
          this.settings.configuration.apiURL +
            "/riviste/form/cb?cod_riv=" +
            this.currentRivistaSelectedId +
            "&anno_riv=" +
            this.currentYearSelected + "&fascicolo1=" + curr_fascicolo,
          header,
          "GET",
          "comboParts",
          null
        );
      }
      else{
        this.filter_onpart = null;
        this.filter_onpages = null;
      }
    },
    filter_onpart(val) {
      if (!val) {
        this.filter_onpages = null;
      }
    }
  },
  async mounted() {
    //this.$gtm.trackView('pageview', this.$route.path);
    console.log("riviste");

    this.$emit("checkQSTicket", this.$route.query); //va messo in tutte le viste
    
    var header = {
      Authorization: localStorage.token_biblio_riv_jwt,
    };

    var d = new Date();
    var session =
      d.getFullYear().toString() +
      d.getMonth().toString() +
      d.getDate().toString() +
      d.getHours() +
      d.getMinutes() +
      d.getSeconds();
    
    if (this.authorization.jwt) {
      this.user = this.authorization;
      this.okRules = this.user.profile.roles.find(x => x == "pay") ? true : false
    } else if (this.settings.configuration) {
      this.$gtm.trackEvent({
        target: "home",
        action: "pageview",
        path: this.$route.path,
        crmId:
          this.authorization &&
          this.authorization.profile &&
          this.authorization.profile.crmId
            ? this.authorization.profile.crmId
            : "free",
        roles:
          this.authorization &&
          this.authorization.profile &&
          this.authorization.profile.roless
            ? this.authorization.profile.roless
            : null,
        session: this.createSessionTimestamp(),
      });
    }
    
    if(localStorage.magazineCall == "true"){ //controllo chiamata doppia
      localStorage.magazineCall = false;
    }

    else if(this.okRules){

    if (this.settings.catalogo && this.settings.catalogo.length) {
      this.materie = this.settings.catalogo.materie;
      /* this.materiaSelected = this.settings.catalogo.materiaSelected
        ? this.settings.catalogo.materiaSelected[0]
        : null;*/

      //this.searchText = this.settings.catalogo.search.nlp.text;
      this.materiaSelected = null;
      this.settings.catalogo.materiaSelected = null;
      this.list = this.settings.catalogo.search.es.list;
      this.totalResults = this.settings.catalogo.search.es.total;
      this.nlp = this.settings.catalogo.search.nlp;
      this.catalogue = this.settings.catalogo.catalogue;
      this.totalPages =
        parseInt(Math.round(this.totalResults / 8)) > 0
          ? parseInt(Math.ceil(this.totalResults / 8))
          : 1;
      //this.chapterText = this.settings.catalogo.search.nlp.textOrig;
      if (this.catalogue) {
      } else {
        this.REQUEST(
          this.settings.configuration.apiURL + "/riviste/form/cb",
          header,
          "GET",
          "comboRiviste",
          null
        );
      }
    } else {
      if (this.settings.configuration) {

        if (localStorage.materie) {
          //controllo la data di scadenza per il refresh, settata a 20 min
          if (localStorage.dateSavedMaterie) {
            var now = Date.parse(new Date()) / 1000;
            var exp =
              parseInt(localStorage.dateSavedMaterie) +
              parseInt(this.settings.configuration.timeoutReloadMaterie);
            if (now >= exp) {
              this.loadMaterie(header);
            } else {
              this.materie = JSON.parse(localStorage.materie);
            }
          } else {
            localStorage.materie = [];
            this.loadMaterie(header);
          }
        } else {
          this.loadMaterie(header);
        }

      var bodyList = {
        sort: {
          name: "cronologico",
          order: "desc",
        },
      };
      bodyList.detail = false;
      await this.REQUEST(
        this.settings.configuration.apiURL +
          "/ricerca/riviste/" +
          0 +
          "/" +
          8 +
          "?nlp=false&jwt_role=true",
        header,
        "POST",
        "list",
        bodyList
      );

       await this.REQUEST(
          this.settings.configuration.apiURL + "/riviste/form/cb",
          header,
          "GET",
          "comboRiviste",
          null
        );
      }
    }
    }
  },
  methods: {
    async REQUEST(call, header, method, type, body) {
      var self = this;
      this.loading = true;
      if (method == "POST") {
        await axios
          .post(call, body, { headers: header })
          .then(function (response) {
            switch (type) {
              case "list":
                self.catalogue = response.data;
                if (self.settings.catalogo)
                  self.settings.catalogo.catalogue = self.catalogue;

                if(self.catalogue.es.list[0]) self.currentItem = self.catalogue.es.list[0];

                localStorage.magazineCall = true;

                self.$forceUpdate();
                break;
              case "search":
                self.list = response.data.es.list;
                self.nlp = response.data.nlp;
                self.totalResults = response.data.es.total;
                self.totalPages =
                  parseInt(Math.round(self.totalResults / 8)) >= 1
                    ? parseInt(Math.ceil(self.totalResults / 8))
                    : 1;
                //self.chapterText = response.data.nlp.textOrig;
                self.loaded = true;
                self.settings.currentSearchWord = self.searchText;
                self.settings.curentFilter = "Le mie riviste";
                var curr_page = (body.filters && body.filters.find(x => x.name == 'pagina')) ? 
                body.filters.find(x => x.name == 'pagina').value : null;
                self.settings.catalogo = {
                  search: response.data,
                  materiaSelected: self.materiaSelected,
                  materie: self.materie,
                  fromDetail: false,
                   filters: {
                    dateFrom: self.dateFrom ? self.dateFrom : body.filters.find(x => x.name == 'range') ? body.filters.find(x => x.name == 'range').value.from : null,
                    dateTo: self.dateTo ? self.dateTo : body.filters.find(x => x.name == 'range') ? body.filters.find(x => x.name == 'range').value.to : null,
                    author: self.author,
                    fromUnlimited: false,
                    fromVolumes: false,
                    fromRiviste: true,
                    pageView: curr_page,
                    qsParamSearch: self.qsParamSearch
                  },
                };

                if (self.showResAdv) {
                  self.$router.push({
                    path: "results",
                    query: { more: true },
                  });
                } else {
                  self.$router.push({
                    path: "results",
                  });
                }
                self.showResAdv = false;
            }
          })
          .catch(function (error) {
            //localStorage.token_biblio_riv = null;
            //localStorage.token_biblio_riv_jwt = self.settings.free.jwt;
            //self.token = self.settings.free.jwt;
            //self.settings.freeToken = true;
          });
      } else {
        await axios
          .get(call, { headers: header })
          .then(function (response) {
            switch (type) {
              case "materie":
                self.materie = response.data;
                self.materie.unshift({
                  descrizione: "Tutto il catalogo".toUpperCase(),
                });
                localStorage.materie = JSON.stringify(self.materie);
                localStorage.dateSavedMaterie = Date.parse(new Date()) / 1000;
                break;

              case "comboRiviste":
                self.rivisteSelect = response.data.riviste;
                break;

              case "comboYear":
                self.yearSelect = response.data.anni;
                break;

              case "comboFascicolo":
                var temp = response.data.fascicoli;
                temp.forEach(element => {
                  element.fascicolo1 = "Fascicolo " + element.fascicolo1 + (element.fascicolo2 ? " - " + element.fascicolo2 : "");
                });
                self.fascicoloSelect = temp;
                break;

              case "comboParts":
                self.partSelect = response.data.parti;
                break;
            }
          })
          .catch(function (error) {
            self.loaded = true;
          });
      }
    },
    fixCopertine(uri){
        return uri.replace(/([^:]\/)\/+/g, '$1')
      },
    toggleFav(){

      var header = {
          Authorization: localStorage.token_biblio_riv_jwt,
        };
      var bodyList = {
        filters: [
          {
            "name": "codiciPreferiti",
            "value": this.pref
          }
        ],
        sort: {
          name: "provvedimento.data",
          order: "desc",
        },
      };
      bodyList.detail = false;
      this.REQUEST(
        this.settings.configuration.apiURL +
          "/ricerca/riviste/" +
          0 +
          "/" +
          8 +
          "?nlp=false&jwt_role=true",
        header,
        "POST",
        "list",
        bodyList
      );
    },
    authorize(obj){
        console.log(obj);
        this.$emit("authorize", null, true); 
    },
    check(val, from) {
      //value <= vm.dateTo || 'Errore nelle date'
      if (from) {
        if (parseInt(val) > parseInt(this.dateTo)) {
          return "Errore nelle date.";
        }
      } else {
        if (val != "" && parseInt(val) < parseInt(this.dateFrom))
          return "Errore nelle date.";
      }
      return true;
    },
    checkOrDisable(type){
      var disable = true;
      if(type == "year") this.filter_onriviste ? disable = false : true;
      if(type == "fascicolo") this.filter_onriviste && this.filter_onyear ? disable = false : true;
      if(type == "part") this.filter_onriviste && this.filter_onyear && this.filter_onfascicolo ? disable = false : true;
      if(type == "page") this.filter_onriviste && this.filter_onyear && this.filter_onfascicolo ? disable = false : true;
      return disable;
    },
    loadMaterie(header) {
      this.REQUEST(
        this.settings.configuration.apiURL + "/materie",
        header,
        "GET",
        "materie",
        null
      );
    },
    moreResults() {
      this.settings.currentSearchWord = null;
      this.settings.curentFilter = "Le mie riviste";
      this.settings.catalogo = null;
      if(this.pref){
        this.$router.push({
        path: "results",
        query: { more: true, fromButton: true, fav: true, riviste: true },
      });
      }
      else{
      this.$router.push({
        path: "results",
        query: { more: true, fromButton: true, riviste: true },
      });
      }
    },
    carousel(item) {
      this.currentItem = this.catalogue.es.list[this.model];
      this.$forceUpdate();
    },
    resetFields(){
      this.dateFrom = null;
      this.dateTo = null;
      this.materiaSelected = null;
      this.filter_onriviste = null;
      this.filter_onyear = null;
      this.filter_onpart = null;
      this.filter_onpages = null;
      this.filter_onfascicolo = null;
      this.author = null;
    },
    setMateria() {
      this.page = 1;
      this.research((this.page - 1) * 8, 8);
    },
    createSessionTimestamp() {
      var d = new Date();
      var session =
        d.getFullYear().toString() +
        d.getMonth().toString() +
        d.getDate().toString() +
        d.getHours() +
        d.getMinutes() +
        d.getSeconds();
      return session;
    },
    setOrder(item) {
      this.currentOrder = item;
      this.research((this.page - 1) * 8, 8);
    },
    verifyUserConditions(item, type) {
      if (type == "unlimited") {
        if (this.settings.freeToken) {
          return false;
        } else {
          if (
            item._source.provvedimento.utente.unlimited == false &&
            item._source.provvedimento.is_unlimited
          ) {
            return true;
          } else return false;
        }
      } else if (type == "abbonato") {
        if (this.settings.freeToken) {
          return false;
        } else {
          if (item._source.provvedimento.utente.volume) {
            return true;
          } else return false;
        }
      }
    },
    generateCover(item) {
      if (
        item._source.provvedimento.campiCalcolati &&
        item._source.provvedimento.campiCalcolati.cover_url &&
        item._source.provvedimento.idDocMaster
      ) {
        return (
          this.settings.copertina +
          "/pdf/" +
          item._source.provvedimento.idDocMaster
            .toString()
            .substring(
              item._source.provvedimento.idDocMaster.toString().length - 3,
              item._source.provvedimento.idDocMaster.toString().length
            ) +
          item._source.provvedimento.campiCalcolati.cover_url +
          "?width=280"
        );
      } else {
        return this.settings.configuration.placeholderIMG;
      }
    },
    detail(item) {
      if(this.settings.catalogo && this.settings.catalogo.filters){
        this.settings.catalogo.filters.pageView = null;
      }
      this.$router.push({
        path: "/details",
        query: { id_doc_master: item._source.provvedimento.idDocMaster },
      });
    },
    keyPress(e) {
      if (e.keyCode === 13) {
        this.page = 1;
        this.research((this.page - 1) * 8, 8);
      }
    },
    research() {
      var body = {};
      var stop = false;
      if ((this.searchText != null && this.searchText.length > 0) || (this.showResAdv)) {
        if (
          this.materiaSelected &&
          this.materiaSelected.descrizione.toUpperCase() != "TUTTO IL CATALOGO"
        ) {
          body = {
            filters: [
              { name: "materia", value: [this.materiaSelected.id.toString()] },
            ],
            sort: this.currentOrder.sort,
          };
        } else {
          body = {
            filters: [],
            sort: this.currentOrder.sort,
          };
        }
        if (this.searchText) {
          body.text = this.searchText;
        }
        if (!this.searchText && this.showResAdv && this.author) {
          //body.text = this.author;
        }

        if (!this.searchText) {
          //PBV-277
          body.sort = {
          name: "cronologico",
          order: "desc",
        };
        } else body.sort = { name: "_score", order: "desc" };

        var flagAuthor = false;
        if (this.showResAdv) {
          var filterDate = {};
          //verifiche
          var dateFrom = this.dateFrom ? this.dateFrom : 1930;
          var dateTo = this.dateTo ? this.dateTo : new Date().getFullYear();
          if (
            parseInt(dateFrom) <= parseInt(dateTo) &&
            parseInt(dateFrom) > 0 &&
            parseInt(dateTo) > 0
          ) {
            if (dateFrom != null || dateTo != null) {
              filterDate.name = "range";
              var values = {
                from: dateFrom
                  ? parseInt(dateFrom)
                  : 1930,
                to: dateTo
                  ? parseInt(dateTo)
                  : 2100,
              };
              filterDate.value = values;
              body.filters.push(filterDate);
            }
            var filterAuthor = {};
            if (this.author != "" && this.author != null) {
              filterAuthor.name = "autore";
              var values = {
                cognome: this.author,
              };
              filterAuthor.value = values;
              body.filters.push(filterAuthor);
              flagAuthor = true;
            }
            var filterRiviste = {};
            if (this.filter_onriviste && this.currentRivistaSelectedId) {
            var filter = this.rivisteSelect.find(
              (x) => x.descrizione == this.filter_onriviste
            );
            this.settings.currentRivistaSelectedId = filter;
            body.filters.push({
              name: "codRiv",
              value: this.currentRivistaSelectedId,
            });
            }
            else{
              this.settings.currentRivistaSelectedId = null;
            }
            var filterAnnoRivista = {};
            if (this.filter_onyear) {
              
              filterAnnoRivista.name = "range";
              filterAnnoRivista.value = {
                "from": parseInt(this.filter_onyear),
                "to": parseInt(this.filter_onyear)
              }
              if(body.filters.find(x => x.name == "range")){
                //PBR-29
                body.filters = body.filters.filter(x => x.name != "range");
                this.dateFrom = null;
                this.dateTo = null;
              }
              body.filters.push(filterAnnoRivista);
            }
            var filterFascicolo = {};
            if (this.filter_onfascicolo) {
              var filter = this.fascicoloSelect.find((x) => x.fascicolo1 == this.filter_onfascicolo);
              this.currentFascicoloSelected = filter.fascicolo1;
              filterFascicolo.name = "fascicolo";
              filterFascicolo.value = parseInt(this.currentFascicoloSelected.replace("Fascicolo ", "").trim());
              body.filters.push(filterFascicolo);
            }
            var filterParti = {};
            if (this.filter_onpart) {
              
              filterParti.name = "parte";
              filterParti.value = this.filter_onpart.toString();
              body.filters.push(filterParti);
            }
            var filterPagine = {};
            if (this.filter_onpages) {
              
              filterPagine.name = "pagina";
              /*filterPagine.value = {
                "pagInizio": 1,
                "pagFine": parseInt(this.filter_onpages)
              }*/
              filterPagine.value = parseInt(this.filter_onpages);
              body.filters.push(filterPagine);
            }
          } else {
            if (this.dateFrom && this.dateTo) stop = true;
          }
        }
        else{
            this.settings.currentRivistaSelectedId = null;           
        }
        if(this.searchText || this.filter_onpart || this.filter_onpages || this.author){
          body.detail = true;
        }
        else{
          body.detail = false;
        }
        if(this.searchText && this.filter_onwords == "Frase esatta"){
          this.qsParamSearch = "&query_type=phrase";
        }
        if (!stop) {
          this.settings.curentFilter = "Tutto il Catalogo";
          var nlp = "";
          var header = {
            Authorization: localStorage.token_biblio_riv_jwt,
          };
          this.REQUEST(
          this.settings.configuration.apiURL +
            "/ricerca/riviste/0/8?nlp=false&jwt_role=true" + (this.qsParamSearch ? this.qsParamSearch : ""),
          header,
          "POST",
          "search",
          body
        );
        }
      }
    },
  },
};
</script>

<style>
.filter__search__select .v-select__selections {
    flex-wrap: initial;
}
</style>