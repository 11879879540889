<template>
  <div>
    <section id="contain">
      <div class="banner-block-gcsp">
        <div class="container maxlayout">
          <div class="row">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-12 banner-block-in-gcsp"
            >
              <div class="border-info-ghp border-info-gcsp">
                <div class="border-info-in-ghp">
                  <div style="margin: auto"></div>
                  <img src="./images/bibl_riv.png" class="img-fluid" />
                  <div class="banner-title-ghp">
                    Tutte le tue riviste, in un unico posto
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-container class="maxlayout">
        <div class="azioni-block-azp">
          <div class="row">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-12 azioni-block-in-azp"
            >
              <div class="intership-title-main-scp2 sotto-title-sop">
                <div class="intership-title-in-scp2">
                  <div class="sotto-title-main-sop">
                    <div class="azioni-title-azp">
                      <h3>
                        Migliaia di fascicoli. Oltre mezzo milione di pagine
                        arricchite da prestigiosi saggi utili per orientarsi in
                        un contesto giuridico sempre più mutevole e incerto
                      </h3>
                    </div>
                  </div>

                  <div class="azioni-tabs-azp clearfix">
                    <div class="azioni-tabs-in-azp">
                      <a
                        @click="authorize()"
                        class="azioni-tabs active"
                        id="attiva_demo"
                        >Accedi</a
                      >
                    </div>
                    <div class="azioni-tabs-in-azp" v-if="settings">
                      <a :href="settings.marketing.url_form_agenti" target="_blank" lass="azioni-tabs" id="chiedi_info"
                        >Richiedi un Contatto</a
                      >
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section-landing row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 online-block-in-ghp">
            <div class="sotto-item-sop">
              <div class="sotto-item-title-sop">
                <h3>Che cos'è Biblioteca Riviste?</h3>
              </div>
              <p class="testo_descrittivo">
                Biblioteca riviste è l’ambiente online contenente il ricco
                archivio storico e vivente di tutte le oltre quaranta riviste
                del catalogo di Giuffrè Francis Lefebvre. Il principale punto di
                riferimento per tutti coloro sono parte del mondo del diritto,
                dove trovare i più esaustivi e autorevoli approfondimenti del
                panorama giuridico nelle sue declinazioni.
              </p>
            </div>
          </div>
        </div>

        <div class="section-landing row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 online-block-in-ghp">
            <div class="container no_padding">
              <div class="row">
                <div class="col-md-4">
                  <div class="immagine_dx" style="margin-bottom: 10px">
                    <img src="images/biblioteca_riviste_books_705x384.jpg" />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="sotto-item-sop">
                    <div class="sotto-item-title-sop">
                      <h3>Le materie</h3>
                    </div>
                    <p class="testo_descrittivo">
                      Un considerevole patrimonio informativo e culturale che
                      permette di spaziare in tutte le materie e le tematiche
                      del diritto: dal diritto civile, al diritto penale, sino
                      al diritto pubblico e altri ambiti via via più specifici e
                      tecnici. L’intero mondo del diritto, a portata di clic.
                    </p>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section-landing row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 online-block-in-ghp">
            <div class="container no_padding">
              <div class="row">
                <div class="col-md-8">
                  <div class="sotto-item-sop">
                    <div class="sotto-item-title-sop">
                      <h3>Le funzionalità</h3>
                    </div>

                    <p class="testo_descrittivo">
                      L’accesso alla biblioteca rivista è riservato ai soli
                      abbonati a una o più riviste. Tutti gli articoli sono
                      consultabili e scaricabili comodamente in formato .pdf, e
                      sono anticipati da pratici abstract (alcuni anche in
                      inglese), utili per individuare rapidamente il tipo di
                      contenuto del documento.
                    </p>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="immagine_dx">
                    <img src="images/biblioteca_riviste_library_705x384.jpg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section-landing row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 online-block-in-ghp">
            <div class="container no_padding">
              <div class="row">
                <div class="col-md-6">
                  <div class="common-title-main-ghp shop-title-ghp">
                    <div class="piu-venduti-dx">
                      <div class="common-title-ghp border-red" style="font-size:24pt">I vantaggi di «Biblioteca riviste»</div>
                      <p class="testo_descrittivo">
                        <strong>Orientamento autorevole</strong><br />
                        Consultare i migliori saggi, nella loro versione PDF, a firma dei più importanti esperti del settore
                        <br />
                        <br />
                        <strong>Pratica Interazione  </strong><br />
                        Scaricare e stampare migliaia di articoli, inserire evidenziazioni e sottolineature sulle parti di contenuto più interessanti
                        <br />
                      </p>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="common-title-main-ghp shop-title-ghp">
                    <div class="piu-venduti-dx">
                      <div class="allinemento_esperto"></div>
                      <p class="testo_descrittivo">
                        <strong>Le riviste preferite</strong><br />
                        Avere un aggiornamento costante sui nuovi fascicoli e i più recenti contenuti di ultima pubblicazione, con la possibilità di creare una lista delle riviste preferite per averle sempre a portata di mano
                        <br />
                        <br />
                        <strong>Oltre 46 anni di storia</strong><br />
                        La maggior parte delle riviste sono presenti in Biblioteca riviste dal 1975. Un patrimonio documentale inestimabile, navigabile anche attraverso pratici indici di fascicolo e di annata
                        <br />
                      </p>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </section>
  </div>
</template>
<script>
export default {
props: { 
    settings: {
      type: Object,
    },
  },
  data: (vm) => ({
    user: null
  }),
  mounted() {
      localStorage.magazineCall = false;
  },
  methods: {
    authorize() {
      this.$emit("authorize", {
        token: localStorage.token_biblio_riv,
        isLogin: true,
      });
    },
  },
};
</script>